<template>
  <div>
    <v-card class="card">
      <!-- <v-img :src="require('~/assets/images/photo.png')" class="card__img" /> -->
      <div class="card__img">
        <img @click="showDetails" :src="article.poster">
      </div>
      <div class="card__content">
        <v-card-title @click="showDetails" class="card__title">
          {{ textLengthFilter(article.title, 15) }}
        </v-card-title>

        <div class="card__sub">
          <div>
            <img src="~/assets/images/cards/views.svg" alt="views icon">
            <p>{{ article.views }}</p>
          </div>
          <div class="price">
            <img src="~/assets/images/cards/comment.svg" alt="comments icon">
            <p>{{ article.comments_count }}</p>
          </div>
        </div>

        <v-card-text class="card__des">
          {{ textLengthFilter(article.brief_description || article.body, 150) }}
        </v-card-text>
        <v-card-actions class="card__btns">
          <FavoriteBtn :item="article" @onFavorite="onFavorite" />
          <v-btn elevation="1" class="show-more" @click="showDetails">
            تفاصيل أكثر
            <img src="~/assets/images/arrow.svg" alt="bug">
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import FavoriteBtn from '~/components/course/FavoriteBtn.vue';
import { useAuthStore } from '~/stores/AuthStore';

const authStore = useAuthStore();
const props = defineProps({
  article: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['onFavorite']);

const router = useRouter();
const { textLengthFilter } = useFilters();

const isLogin = computed(() => authStore.auth.isLoggedIn);

const showDetails = () => {
  navigateTo(`/articles/${props.article.id}`);

  // router.push({
  //   name: 'articles-id',
  //   params: { id: props.article.id },
  // });
};

const onFavorite = () => {
  emit('onFavorite', { value: true });
};

</script>

<script>
import windowSize from '~/mixins/window-size';
export default {
  mixins: [windowSize],
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 30px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  * {
    word-break: break-word !important;
  }
  .card__img {
    width: 40%;
    img {
      cursor: pointer;
      width: 100% !important;
      height: 100%;
      border-radius: 0 20px 20px 0 !important;
    }
  }
  .card__content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 12px;
    .card__title {
      cursor: pointer;
      display: flex;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
    }
    .card__sub {
      display: flex;
      justify-content: flex-start;
      font-size: 13px;
      font-weight: bold;
      margin-right: 10px;
      div {
        display: flex;
        flex-wrap: wrap;
        img {
          margin: 0px 10px;
        }
      }
    }
    .price {
      position: relative;
      margin-right: 30px !important;
      &:before {
        content: "";
        width: 7px;
        height: 8px;
        margin: 0px;
        background-color: #bcbcbc;
        border-radius: 50%;
        position: absolute;
        right: -15px;
        top: 5px;
      }
    }
    .card__btns {
      justify-content: space-between;
      padding: 0px 24px 8px;
      .show-more {
        border-radius: 18px;
        background-color: #f4f4f4;
        font-size: 18px;
        font-weight: bold;
        img {
          margin-right: 20px;
        }
      }
    }
    .card__des {
      font-size: 15px;
      font-weight: normal;
      line-height: 1.6;
      text-align: right;
      min-height: 90px;
    }
  }
}
@media (max-width: 1264px) {
  .card {
    .card__content {
      .card__title {
        font-size: 16px;
      }
      .card__btns {
        .show-more {
          border-radius: 12px;
        }
        .show-more {
          font-size: 13px;
          img {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .card {
    // .card__img {
    //   width: 39% !important;
    // }
    .card__content {
      .card__title {
        font-size: 22px;
        min-height: 85px;
      }
      .card__btns {
        .show-more {
          border-radius: 12px;
        }
        .show-more {
          padding: 6px 4.5px;
          font-size: 13px;
          img {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 800px) and (min-width: 600px) {
  .card__btns {
    justify-content: space-around;
    padding: 0px 8px 8px !important;
  }
}
@media (max-width: 600px) {
  .card {
    // .card__img {
    //   width: 36% !important;
    // }
    .card__content {
      .card__title {
        font-size: 22px;
        min-height: auto;
      }
      .card__des {
        padding: 10px;
      }
      .card__sub {
        font-size: 12px;
        margin-right: 0px;
        div {
          margin: 0px 5px 0 5px !important;
          margin: 0px 10px 0 10px;
        }
      }
      .price {
        margin-right: 20px !important;
        &:before {
          right: -5px;
        }
      }
      .card__btns {
        padding: 0 10px !important;
        .show-more {
          padding: 6px 4.5px;
          font-size: 16px;
          border-radius: 10px;
          img {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
